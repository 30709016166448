<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Ventancat</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Bienvenido a Ventancat! 👋
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="success"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
              <b-button
                type="button"
                variant="primary"
                block
                link
                to="/register"
              >
                Registrarse
              </b-button>
              <b-button
                type="button"
                variant="warning"
                block
                link
                to="/recover"
              >
                Recuperar Contaseña
              </b-button>
              <div class="text-center boxPolities">
                <b-nav>
                  <b-nav-item :href="'politica-cookies/'"
                    >Política de cookies</b-nav-item
                  >
                  <b-nav-item :href="'politica-privacidad/'"
                    >Política privacidad</b-nav-item
                  >
                  <b-nav-item :href="'politica-desistimiento/'"
                    >Derecho desestimiento</b-nav-item
                  >
                </b-nav>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
    <div>
      <b-modal v-model="modalShow" id="modal-1" title="Aviso de Cookies">
        <p class="my-4">
          Utilizamos cookies propias y de terceros para mejorar tu experiencia
          de navegación, y ofrecer contenidos de interés. Has click para aceptar
          nuestra política de cookies.
          <a :href="'politica-cookies/'">Leer más</a>
        </p>

        <template #modal-footer="{ ok, cancel }">
          <b-button size="sm" variant="success" @click="aceptar()">
            Aceptar
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancelar
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
  <!-- /Login-->
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BNav,
  BModal,
  BNavItem,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BNav,
    BModal,
    BNavItem,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: true,
      password: "P@ssw0rd",
      userEmail: "juan.duarte@evotic.es",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      modalShow: this.getCookie('username') !== '_vc_user' ? true : false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    checkCookie() {
      let user = this.getCookie("username");   
      if (user != '_vc_user') {
        user = '_vc_user';
        if (user != "" && user != null) {
          this.setCookie("username", user, 365);
        }
      }
    },
    aceptar() {
      this.modalShow = false;
      this.checkCookie();     
    },
    cancel() {
      modalShow = false;
    },
    async login() {
      await this.$store.dispatch("auth/login", {
        email: this.userEmail,
        password: this.password,
        remember_me: this.status,
      });
      if (this.$store.state.auth.is_auth) {
        this.$router.push("/");
      } else {
        alert("no está autentificado");
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    me() {
      this.$http.get("/api/auth/user").then((response) => {
        window.console.log(data);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.polities {
  padding-right: 2%;
  color: black;
  font-size: 12px;
}
.boxPolities {
  margin-top: 40px;
  width: 98%;
}
[dir] .nav-link {
  color: black !important;
}
[dir=ltr] .modal-header .close{
  margin:0!important;
}
</style>
